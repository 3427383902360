import {Brand, Card, CardContent, Page} from '@casecommons/cbp-undercase'

interface AuthenticationPageProps {
  children: JSX.Element
  branded?: boolean
}

const AuthenticationPage = ({
  children,
  branded = true,
}: AuthenticationPageProps) => (
  <Page content size='smallContent'>
    <Card transparent>
      {branded && (
        <CardContent>
          <Brand color='white' />
          <Brand color='white' padded productName='Casebook' hideLogo />
        </CardContent>
      )}
      {children}
    </Card>
  </Page>
)

export default AuthenticationPage
