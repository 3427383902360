import {FormItem, TextField} from '@casecommons/cbp-undercase'

export const EMPTY_PASSWORD_ERROR = 'Password cannot be empty'
export const MULTIPLE_ERROR_MESSAGES_MSG = 'Password must have:'

interface PasswordFieldProps {
  id?: string
  name?: string
  label?: string
  password: string
  error?: boolean
  errorMessage?: string | string[]
  onBlur?: () => void
  onChange: (event: any) => void
  onPressEnter?: () => void
  autoComplete?: string
}

export default ({
  id = 'password-field',
  password,
  error,
  onBlur,
  onChange,
  name = 'password',
  label = 'Password',
  errorMessage,
  onPressEnter,
  autoComplete,
}: PasswordFieldProps) => {
  let ErrorMessages = null
  if (error) {
    ErrorMessages = Array.isArray(errorMessage) ? (
      <>
        <div>{MULTIPLE_ERROR_MESSAGES_MSG}</div>
        {errorMessage.map((message: string) => (
          <div key={message}>- {message}</div>
        ))}
      </>
    ) : (
      errorMessage || EMPTY_PASSWORD_ERROR
    )
  }

  const handleKeyPress = (event: any) => {
    const charCode =
      typeof event.which === 'number' ? event.which : event.keyCode
    if (charCode === 13 && onPressEnter) {
      onPressEnter()
    }
  }

  return (
    <FormItem size='fullWidth'>
      <TextField
        id={id}
        testID={`input-${name}`}
        label={label}
        name={name}
        type='password'
        value={password}
        error={error}
        helperText={ErrorMessages}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        required
        noticeable={false}
        autoComplete={autoComplete}
      />
    </FormItem>
  )
}
