import {FormItem, TextField} from '@casecommons/cbp-undercase'

export const INVALID_EMAIL_ERROR = 'Please enter a valid email address'

interface EmailFieldProps {
  id?: string
  email: string
  autoFocus?: boolean
  error?: boolean
  disabled?: boolean
  onBlur?: () => void
  onChange: (event: any) => void
  onPressEnter?: () => void
  autoComplete?: string
}

export default ({
  id = 'email-field',
  email,
  error,
  onBlur,
  onChange,
  onPressEnter,
  disabled,
  autoFocus = true,
  autoComplete,
}: EmailFieldProps) => {
  const handleKeyPress = (event: any) => {
    const charCode =
      typeof event.which === 'number' ? event.which : event.keyCode
    if (charCode === 13 && onPressEnter) {
      onPressEnter()
    }
  }

  return (
    <FormItem size='fullWidth'>
      <TextField
        id={id}
        testID='input-email'
        label='Email address'
        type='email'
        name='email'
        value={email}
        error={error}
        helperText={error && INVALID_EMAIL_ERROR}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        required
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        noticeable={false}
        disabled={disabled}
      />
    </FormItem>
  )
}
