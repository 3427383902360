import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import {churnZeroPlugin, CoreProvider} from '@casecommons/cbp-core-typescript'
import {AuthenticationOptionalProvider} from '@casecommons/cbp-react-auth'

import Analytics from 'analytics'
import {render} from 'react-dom'
import {AnalyticsProvider} from 'use-analytics'

import {Layout} from './components'
import routesConfig from './routes-config'

const {gaId, gtmId, churnZeroAppKey, churnZeroSubdomain} = (window as any)
  .appInfo

const analytics = Analytics({
  plugins: [
    googleAnalytics({
      measurementIds: [gaId],
      dataLayerName: 'cbpDataLayer',
    }),
    googleTagManager({
      containerId: gtmId,
      dataLayerName: 'cbpDataLayer',
    }),
    churnZeroPlugin({
      appKey: churnZeroAppKey,
      subdomain: churnZeroSubdomain,
      moduleName: 'Authentication',
    }),
  ],
})

/**
 * If the user ID includes one of the casebook domains, disable the "churn-zero" plugin.
 */
const userId = localStorage.getItem('__user_id')
const casebookDomains = ['@casebook.net', '@sysvine.com']

if (userId && casebookDomains.some((domain) => userId.includes(domain))) {
  analytics.plugins.disable(['churn-zero'], () => {})
}

analytics.on('trackStart', ({payload}) => {
  const {properties} = payload
  properties.appName = 'Authentication'
})

const App = () => (
  <AnalyticsProvider instance={analytics}>
    <CoreProvider routesConfig={routesConfig}>
      <AuthenticationOptionalProvider>
        <Layout />
      </AuthenticationOptionalProvider>
    </CoreProvider>
  </AnalyticsProvider>
)

__webpack_public_path__ =
  'env' in window && (window as any).env.CBP_CDN_BASE_URL
    ? `${(window as any).env.CBP_CDN_BASE_URL}${__webpack_public_path__}`
    : __webpack_public_path__

render(<App />, document.getElementById('root'))
