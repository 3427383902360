import {FormItem, TextField} from '@casecommons/cbp-undercase'

interface NameFieldProps {
  label: string
  name: string
  value: string
  required?: boolean
  error?: boolean
  autoFocus?: boolean
  onBlur?: () => void
  onChange: (event: any) => void
  autoComplete?: string
}

export default ({
  value,
  name,
  required = true,
  error,
  onBlur,
  onChange,
  label,
  autoFocus = false,
  autoComplete,
}: NameFieldProps) => {
  const INVALID_ERROR = `Please enter a valid ${label}`
  return (
    <FormItem size='fullWidth'>
      <TextField
        testID={`input-${name}`}
        label={label}
        type='text'
        name={name}
        value={value}
        error={error}
        helperText={error && INVALID_ERROR}
        onBlur={onBlur}
        onChange={onChange}
        required={required}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        noticeable={false}
      />
    </FormItem>
  )
}
