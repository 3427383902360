import {Router} from '@casecommons/cbp-core-typescript'
import {LoadingPage, Page} from '@casecommons/cbp-undercase'
import {useEffect, Suspense} from 'react'
import {useAnalytics} from 'use-analytics'

import useCurrentUser from '../hooks/useCurrentUser'

const Layout = () => {
  const currentUser = useCurrentUser()
  const {identify, page} = useAnalytics()
  const {firstName, name, lastName, email, id, tenant, volunteer} =
    currentUser || {}
  const {subdomain} = (window as any).tenantInfo
  const {domain} = (window as any).appInfo
  const companyId = `${subdomain}.${domain}`
  const userId = `${email}_${companyId}`

  useEffect(() => {
    identify(userId, {
      user_id: userId,
      email,
      name,
      firstName,
      lastName,
      volunteer,
      tenantId: tenant?.id,
      tenantName: tenant?.name,
      custom_attributes: {
        userId: id,
      },
      company: {
        id: companyId,
        name: tenant?.name,
        custom_attributes: {
          subdomain,
          tenantId: tenant?.id,
        },
      },
    })
    page()
  }, [id])

  return (
    <Suspense fallback={<LoadingPage />}>
      <Page color='heather-patina' topLevel>
        <Page main>
          <Router />
        </Page>
      </Page>
    </Suspense>
  )
}

export default Layout
