import {IRoutesConfig} from '@casecommons/cbp-core-typescript'
import {lazy} from 'react'

export const ROUTE_LOGIN_PATH = '/login'
export const ROUTE_LOGOUT_PATH = '/logout'
export const ROUTE_RECOVER_PASSWORD_PATH = '/recover-password'
export const ROUTE_RESET_PASSWORD_PATH = '/reset-password'
export const ROUTE_SET_NEW_PASSWORD_PATH = '/set-new-password'
export const ROUTE_SELF_REGISTRATION_PATH = '/register'
export const ROUTE_CONFIRM_MFA_PATH = '/validate-code'
export const ROUTE_CALLBACK_PATH = '/callback'

const config: IRoutesConfig = {
  basePath: '/authentication',
  routesConfig: [
    {
      Component: lazy(() => import('./pages/Login')),
      default: true,
      path: ROUTE_LOGIN_PATH,
    },
    {
      Component: lazy(() => import('./pages/Logout')),
      path: ROUTE_LOGOUT_PATH,
    },
    {
      Component: lazy(() => import('./pages/RecoverPassword')),
      path: ROUTE_RECOVER_PASSWORD_PATH,
    },
    {
      Component: lazy(() => import('./pages/ResetPassword')),
      path: ROUTE_RESET_PASSWORD_PATH,
    },
    {
      Component: lazy(() => import('./pages/SetNewPassword')),
      path: ROUTE_SET_NEW_PASSWORD_PATH,
    },
    {
      Component: lazy(() => import('./pages/SelfRegistration')),
      path: ROUTE_SELF_REGISTRATION_PATH,
    },
    {
      Component: lazy(() => import('./pages/MFAVerifyCode')),
      path: ROUTE_CONFIRM_MFA_PATH,
    },
    {
      Component: lazy(() => import('./pages/Callback')),
      path: ROUTE_CALLBACK_PATH,
    },
  ],
}

export default config
