import {CurrentUser, OptionalUserContext} from '@casecommons/cbp-react-auth'
import {useContext} from 'react'

export default function useCurrentUser(): CurrentUser | null {
  const context = useContext(OptionalUserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a OptionalUserContext')
  }
  return context.user
}

export function useUpdateUser(): () => Promise<any> {
  const context = useContext(OptionalUserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a OptionalUserContext')
  }
  return context.updateUser
}
